<template>
  <div class="box">
    <template v-if="audioId == 16">
      <div
        class="audiodetail_top"
        style="
          height: 100%;
          background-image: url('@/common/img/atoshi/gecibg.png');
          background-size: 100% 100%;
          position: fixed;
          top: 0;
          left: 0;
        "
      >
        <div class="audio_caozuo2">
          <img
            :src="imagesAddressCommon + '/xiazai_audio.png'"
            @click="toxiazai"
            class="xiazai_audio"
            v-show="isshowxiazai"
          />
          <img
            :src="imagesAddressCommon + '/fenxiang_audio.png'"
            @click="tofenxiang"
            class="fenxiang_audio"
            v-show="isshowfenxiang"
          />
        </div>
        <img
          :src="imagesAddressCommon + '/ximalaya_audio.png'"
          class="ximalaya_audio"
          @click="toximalaya"
          v-if="details.audioLink"
        />
        <img
          :src="imagesAddressCommon + '/atoshi_logo.png'"
          class="atoshi_logo"
        />
        <img
          :src="contralImg"
          class="contral"
          data-action="play"
          id="btn-play"
        />
        <div class="audio_title" @click="toshowtitle">
          {{ details.audioTitle }}
        </div>
        <div class="biaozhunxian" id="biaozhunxian">
          <p>---------</p>
          <p>---------</p>
        </div>
        <div class="geci" id="geci">
          <div id="geci_cont" ref="gecicont">
            <ul id="lrclist">
              <!-- 保证歌词在正中间 -->
            </ul>
          </div>
        </div>
        <div class="jAudio--progress-bar" style="margin-bottom: 0.2rem">
          <div class="jAudio--progress-bar-wrapper">
            <div class="jAudio--progress-bar-played">
              <span class="jAudio--progress-bar-pointer"></span>
            </div>
          </div>
        </div>
        <div class="jAudio--time" style="margin-bottom: 0.3rem">
          <span class="jAudio--time-elapsed">00:00</span>
          <span class="jAudio--time-total">00:00</span>
        </div>
        <audio
          ref="audio"
          :src="details.audioFileUrl"
          id="audio_wenjian"
          style="opacity: 0"
        ></audio>
      </div>
      <div
        class="audiodetail_des"
        id="des1"
        style="
          width: 7.5rem;
          height: 1rem;
          padding: 0.6rem 0.6rem;
          padding-bottom: 0.8rem;
          position: fixed;
          bottom: 0;
          left: 0;
          background: #ffffff;
        "
        v-if="!iszhankai"
        @click="tozhankai()"
      >
        <div class="audiodetail_des_title">介绍</div>
        <img
          :src="imagesAddressAddressZh + '/zhankai.png'"
          class="zhankai"
          @click.stop="tozhankai()"
        />
      </div>
      <div class="zhezhaoceng" v-if="iszhankai" @click.self="toshouqi()">
        <div
          class="audiodetail_des zhankai_box"
          id="des2"
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            height: auto;
            background: #ffffff;
          "
        >
          <div class="audiodetail_des_title">介绍</div>
          <img
            :src="imagesAddressAddressZh + '/zhankai.png'"
            class="shouqi"
            @click.stop="toshouqi()"
            style="transform: rotate(180deg)"
          />
          <div class="audiodetail_des_cont shouqi_des">
            {{ details.audioIntroduce ? details.audioIntroduce : "暂无介绍" }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="audioId != 16">
      <div class="audiodetail_top">
        <img
          :src="imagesAddressCommon + '/ximalaya_audio.png'"
          class="ximalaya_audio"
          @click="toximalaya"
          v-if="details.audioLink"
        />
        <img
          :src="contralImg"
          class="contral"
          data-action="play"
          id="btn-play"
        />
        <div class="audio_title martop" @click="toshowtitle">
          {{ details.audioTitle }}
        </div>
        <div class="audio_caozuo2">
          <img
            :src="imagesAddressCommon + '/xiazai_audio.png'"
            @click="toxiazai"
            class="xiazai_audio"
            v-show="isshowxiazai"
          />
          <img
            :src="imagesAddressCommon + '/fenxiang_audio.png'"
            @click="tofenxiang"
            class="fenxiang_audio"
            v-show="isshowfenxiang"
          />
        </div>
        <div class="jAudio--progress-bar martop2">
          <div class="jAudio--progress-bar-wrapper">
            <div class="jAudio--progress-bar-played">
              <span class="jAudio--progress-bar-pointer"></span>
            </div>
          </div>
        </div>
        <div class="jAudio--time">
          <span class="jAudio--time-elapsed">00:00</span>
          <span class="jAudio--time-total">00:00</span>
        </div>
        <audio
          :src="details.audioFileUrl"
          id="audio_wenjian"
          style="opacity: 0"
        ></audio>
      </div>
      <div class="audiodetail_des">
        <div class="audiodetail_des_title">介绍</div>
        <div class="audiodetail_des_cont">
          {{ details.audioIntroduce ? details.audioIntroduce : "暂无介绍" }}
        </div>
      </div>
    </template>
    <div class="webdown_box" v-show="isshowzhezhao" @click="toguanbi">
      <img :src="imagesAddressCommon + '/right_share.png'" />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { enumsImages, enumsZh } from "@/utils/enum.js";
import '@/common/js/jaudio.js'
export default {
  name: "audiodetail",
  data() {
    return {
      audioId: "",
      details: {},
      contralImg: "",
      action: false,
      userAgent: null,
      isshowcaozuo: false,
      isshowzhezhao: false,
      iszhankai: false,
      isshowfenxiang: false,
      isshowxiazai: false,
      audioDom: null,
      imagesAddressCommon: enumsImages.addressCommon,
      imagesAddressAddressZh: enumsImages.addressZh,
    };
  },
  methods: {
    // 判断是否是app内部
    judgeApp() {
      // 需要ios 安卓传递参数
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        try {
          let isApp = window.Android.isApp();
          if (isApp == "android_yuanzilian") {
            this.isshowfenxiang = true;
            this.isshowxiazai = true;
          } else {
            this.isshowfenxiang = false;
            this.isshowxiazai = false;
          }
        } catch (err) {
          this.isshowfenxiang = false;
          this.isshowxiazai = false;
        }
      }
      if (isiOS) {
        try {
          //1、通知IOS 传参数; isApp ios方法
          window.webkit.messageHandlers.isApp.postMessage({});
          window.isApp = this.isApp;
        } catch (err) {
          this.isshowfenxiang = false;
          this.isshowxiazai = false;
        }
      }
    },
    //获取ios
    isApp: function (value) {
      if (value == "ios_yuanzilian") {
        this.isshowfenxiang = true;
      } else {
        this.isshowfenxiang = false;
      }
    },
    toshowtitle() {
      this.$dialog.alert({
        message: this.details.audioTitle,
        width: 300,
      });
    },
    tozhankai() {
      this.iszhankai = true;
    },
    toshouqi() {
      this.iszhankai = false;
    },
    getDetails() {
      // server.findAudioById({ audioId: this.audioId }).then((res) => {
      //   console.log(res);
      //   this.details = res.data.data.data;
      //   this.contralImg = this.imagesAddressCommon + "/bofang_audio.png";
      //   $(".audiodetail_top").jAudio();
      //   if (this.audioId == 16) {
      //     this.gecishishi();
      //   }
      // });
      let that = this,
        data = {
          audioId: this.audioId
        }
      $.ajax({
        type: "post", //提交方式
        url: "https://official.juhaowu.cn/api/audio/findAudioById", //路径
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data), // 数据
        success: function (res) {
          //返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data.data;
            that.details = resData;
            that.contralImg = that.imagesAddressCommon + "/bofang_audio.png";
            $(".audiodetail_top").jAudio();
            if (that.audioId == 16) {
              that.gecishishi();
            }
          }
        },
      });
    },
    gecishishi() {
      var _this = this;
      var lrcJSON = {
        "[00:1.88]": "小原子也可以点亮世界",
        "[00:19.71]": "词曲：岳明华/廖望",
        "[00:26.81]": "人就像一颗原子",
        "[00:29.24]": "很微小，但潜力无限",
        "[00:32.03]": "链接释放的能量",
        "[00:35.55]": "可以点亮世界",
        "[00:39.01]": "星火燎原",
        "[00:41.21]": "每天点亮一个角落",
        "[00:44.29]": "给世界加一点能量",
        "[00:51.41]": "原子链",
        "[00:53.84]": "链接你我",
        "[00:56.73]": "链接未来",
        "[01:02.40]": "向公正平等更加高效的明天",
        "[01:08.40]": "奔跑而去",
        "[01:09.48]": "我们的生命为它欢腾",
        "[01:15.00]": "原子币",
        "[01:17.03]": "像一颗坚实的种子",
        "[01:20.46]": "破土而出",
        "[01:21.39]": "顶着烈日",
        "[01:23.00]": "迎着风雨",
        "[01:26.43]": "向枝繁叶茂的春天",
        "[01:31.84]": "野蛮生长",
        "[01:33.00]": "我们的脚步为它而奔腾",
        "[01:39.09]": "让我们手牵手",
        "[01:44.17]": "一起点亮世界！",
        "[01:51.23]": "让我们心连心",
        "[01:56.67]": "一起点亮世界！",
        "[02:03.11]": "让我们链接一起",
        "[02:05.92]": "尽情释放我们的潜能！",
        "[02:09.18]": "一起点亮世界！",
        "[02:14.86]": "一起点亮世界！",
        "[02:20.52]": "一起点亮世界！",
        "[02:49.00]": "",
      };
      var lrcTime = []; //歌词对应的时间数组
      var ul = $("#lrclist")[0]; //获取ul

      var i = 0;
      $.each(lrcJSON, function (key) {
        //遍历lrc
        lrcTime[i++] =
          parseFloat(key.substr(1, 3)) * 60 + parseFloat(key.substring(4, 10)); //00:00.000转化为00.000格式
        ul.innerHTML += "<li><p>" + lrcJSON[key] + "</p></li>"; //ul里填充歌词
      });
      lrcTime[lrcTime.length] = lrcTime[lrcTime.length - 1] + 3; //如不另加一个结束时间，到最后歌词滚动不到最后一句

      // var geci_cont = document.getElementById("geci_cont");
      var $li = $("#lrclist>li"); //获取所有li

      var currentLine = 0; //当前播放到哪一句了
      var currentTime; //当前播放的时间
      var audio = document.getElementById("audio_wenjian");
      // var ppxx; //保存ul的translateY值

      audio.ontimeupdate = function () {
        //audio时间改变事件
        currentTime = audio.currentTime;
        var j, len;
        for (j = currentLine, len = lrcTime.length; j < len; j++) {
          if (currentTime < lrcTime[j + 1] && currentTime > lrcTime[j]) {
            currentLine = j;
            let y = -40 * currentLine;
            $("#geci_cont").find("li").removeClass("on");
            $li.get(currentLine).className = "on";
            $("#geci_cont").css({ top: y / 50 + "rem" });
            break;
          }
        }
      };

      audio.onseeked = function () {
        //audio进度更改后事件
        $("#lrclist").find("li").removeClass("on");
        currentTime = audio.currentTime;
        if (currentTime < 1) {
          $("#geci_cont").css({
            top: "0.4rem",
          }); //控件新位置
        }
        var k, len;
        for (k = 0, len = lrcTime.length; k < len; k++) {
          if (currentTime < lrcTime[k + 1] && currentTime > lrcTime[k]) {
            currentLine = k;
            break;
          }
        }
      };
      audio.addEventListener(
        "play",
        function () {
          $(".contral_top").attr(
            "src",
            _this.imagesAddressCommon + "/zanting.png"
          );
          $(".contral").attr(
            "src",
            _this.imagesAddressCommon + "/zanting_audio.png"
          );
          $(".contral").data("action", "pause");
          $(".contral").attr("id", "btn-pause");
          audio.play();
        },
        false
      );
      audio.addEventListener(
        "pause",
        function () {
          $(".contral_top").attr(
            "src",
            _this.imagesAddressCommon + "/bofang.png"
          );
          $(".contral").attr(
            "src",
            _this.imagesAddressCommon + "/bofang_audio.png"
          );
          $(".contral").data("action", "play");
          $(".contral").attr("id", "btn-play");
          audio.pause();
        },
        false
      );
      audio.addEventListener(
        "ended",
        function () {
          $("#lrclist").html(" ");
          _this.gecishishi();
          $(".contral_top").attr(
            "src",
            _this.imagesAddressCommon + "/bofang.png"
          );
          $(".contral").attr(
            "src",
            _this.imagesAddressCommon + "/bofang_audio.png"
          );
          $(".contral").data("action", "play");
          $(".contral").attr("id", "btn-play");
          audio.pause();
        },
        false
      );

      var _move = false; //移动标记
      var _y; //鼠标离控件左上角的相对位置
      var line = 0;
      $(document).ready(function () {
        document.getElementById("geci_cont").addEventListener(
          "touchstart",
          function (e) {
            _move = true;
            var touch = e.touches[0]; //获取第一个触点
            _y = Number(touch.pageY) - parseInt($("#geci_cont").css("top"));
          },
          false
        );
        document.addEventListener(
          "touchmove",
          function (e) {
            e.preventDefault();
            if (parseInt($("#geci_cont").css("top")) < -1280) {
              _move = false;
              return;
            }
            if (parseInt($("#geci_cont").css("top")) > 20) {
              $("#geci_cont").css({
                top: "0.4rem",
              });
              _move = false;
              return;
            }
            if (_move) {
              // audio.pause();
              // console.log(parseInt($("#treeDiv").css("top")),parseInt($("#treeDiv").css("top"))/30,parseInt(Math.abs(parseInt($("#treeDiv").css("top")))/40));
              //拖动后的第几行
              line = parseInt(
                Math.abs(parseInt($("#geci_cont").css("top"))) / 40
              );
              $li.get(line).className = "on";
              audio.currentTime = lrcTime[line];
              currentLine = line;

              var touch = e.touches[0]; //获取第一个触点
              var y = Number(touch.pageY) - _y;

              $("#geci_cont").css({
                top: y / 50 + "rem",
              }); //控件新位置
            }
          },
          false
        );
        document.addEventListener(
          "touchend",
          function () {
            if (_move) {
              _move = false;
              // audio.play();
            }
          },
          false
        );
      });
    },
    // 下载app 打开外链
    downloadApp(url) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        // console.log("安卓安卓",isAndroid)
        try {
          // console.log("安卓安卓")
          window.Android.downloadSeaAtoshi(url);
        } catch (error) {
          // console.log("安卓error",error)
          window.location.href = url;
        }
      } else if (isiOS) {
        // console.log("苹果苹果",isiOS)
        try {
          // console.log("iosiosiosios")
          window.webkit.messageHandlers.downloadSeaAtoshi.postMessage(url);
        } catch (error) {
          // console.log("苹果error",error)
          window.location.href = url;
        }
      } else {
        // console.log("安卓安卓else")
        // console.log("苹果else")
        window.location.href = url;
      }
    },
    toximalaya() {
      this.downloadApp(this.details.audioLink);
    },
    toxiazai() {
      window.location.href = this.details.audioFileUrl;
      var isWeixin = !!/MicroMessenger/i.test(this.userAgent);
      if (isWeixin) {
        this.$dialog.alert({
          message:
            "点击右上角按钮，然后再弹出的菜单中，点击在Safari中打开，即可下载该音频",
          width: 250,
        });
      } else {
        if (this.userAgent.indexOf("Android") > -1) {
          window.Android.downloadAudio(
            this.details.audioFileUrl,
            this.details.audioFileName,
            "zh"
          );
        } else if (/^.+(Mobile\/\w+)\s?$/.test(this.userAgent)) {
          window.webkit.messageHandlers.callCustomerServiceiOS.postMessage();
        } else {
          location.href =
            enumsZh.address +
            "/api/audio/downloadAudio?audioName=" +
            this.details.audioFileName;
        }
      }
    },
    tofenxiang() {
      let _this = this;
      if (/^.+(Mobile\/\w+)\s?$/.test(_this.userAgent)) {
        let datas = JSON.stringify({
          fxtitle: _this.details.audioTitle,
          fxurl: _this.details.audioFileUrl,
          fxcoverimg: _this.details.readCover,
        });
        window.webkit.messageHandlers.shareNewsToiPhone.postMessage(datas);
      } else {
        // _this.isshowzhezhao = true
        try {
          window.Android.shareNewsFromWebsite(
            _this.details.audioTitle,
            _this.details.audioFileUrl,
            _this.details.readCover
          );
        } catch (err) {
          this.$dialog.alert({
            message: "请点击浏览器分享按钮进行分享",
            width: 250,
          });
        }
      }
    },
    toguanbi() {
      this.isshowzhezhao = false;
    },
    // 提供给安卓调用 音频暂停
    audioPause() {
      // alert("恭喜大佬 调用成功！")
      this.audioDom = this.$refs.audio;
      this.audioDom.pause();
      this.audioDom.addEventListener(
        "play",
        function () {
          this.audioDom.pause();
        },
        false
      );
    },
  },
  mounted() {
    this.audioId = this.$route.query.audioId;
    this.getDetails();
    this.judgeApp();
    this.userAgent = navigator.userAgent;
    // 提供给安卓调用 音频暂停
    window.audioPause = this.audioPause();
    // if (this.userAgent.indexOf("Android") > -1) {
    //     this.isshowfenxiang=true
    // } else if (/^.+(Mobile\/\w+)\s?$/.test(this.userAgent)) {
    //     this.isshowfenxiang=false
    // } else {
    //     this.isshowfenxiang=true
    // }
  },
  beforeDestroy() {
    this.audioDom = this.$refs.audio;
    // console.log("beforeDestroy",this.audioDom)
    this.audioDom.pause();
    this.audioDom.addEventListener(
      "play",
      function () {
        this.audioDom.pause();
      },
      false
    );
  },
};
</script>

<style scoped>
@import '../../../common/css/audio.css'; 
.box {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.audiodetail_top {
  width: 7.5rem;
  min-height: 7rem;
  margin: 0 auto;
  background-image: url("https://video2.juhaowu.cn/static-web/mobile_img/common/img/audio_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.audio_caozuo2 {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.ximalaya_audio {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  width: 1.2rem;
  height: 0.4rem;
}
.atoshi_logo {
  position: absolute;
  top: 1.11rem;
  left: 2.55rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.16rem;
  z-index: 0;
}
.contral {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  margin-top: 1.75rem;
  margin-left: 3.15rem;
  position: relative;
  z-index: 10;
}
.audio_title {
  width: 90%;
  font-size: 0.42rem;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 0.8rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.audio_caozuo {
  width: 2.4rem;
  margin: 0 auto;
  margin-top: 0.23rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.xiazai_audio,
.fenxiang_audio {
  width: 1.1rem;
  height: 0.4rem;
  margin-left: 5px;
}
.audiodetail_des {
  width: 6.7rem;
  margin: 0 auto;
  margin-top: 0.42rem;
}
.audiodetail_des_title {
  height: 0.29rem;
  line-height: 0.31rem;
  color: #333333;
  font-size: 18px;
  padding-left: 0.1rem;
  border-left: 0.05rem solid #00a0ea;
}
.audiodetail_des_cont {
  width: 100%;
  font-size: 0.28rem;
  color: #666666;
  margin-top: 0.25rem;
  line-height: 0.52rem;
}
.webdown_box {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  z-index: 200;
  top: 0;
}
.webdown_box img {
  width: 3.5rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
}
.zhankai {
  width: 0.45rem;
  position: absolute;
  top: 0.45rem;
  right: 0.6rem;
}
.shouqi {
  width: 0.45rem;
  position: absolute;
  top: 0.4rem;
  right: 0.6rem;
}
.zhankai_box {
  width: 7.5rem;
  padding: 0.6rem 0.6rem;
}
.shouqi_des {
  min-height: 1.5rem;
  max-height: 4.5rem;
  overflow-y: scroll;
}
.zhezhaoceng {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
}
.biaozhunxian p:nth-child(1) {
  position: absolute;
  top: 7rem;
  left: 0.34rem;
  color: #00a0ea;
}
.biaozhunxian p:nth-child(2) {
  position: absolute;
  top: 7rem;
  right: 0.34rem;
  color: #00a0ea;
}
</style>
<style></style>
